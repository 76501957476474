import axios from '@/common/axios'
import qs from 'qs'

export function addQualityInformationFeedback (data) {
  return axios({
    method: 'post',
    url: '/universal/qualityInformationFeedback/add',
    data: qs.stringify(data)
  })
}

export function deleteQualityInformationFeedback (id) {
  return axios({
    method: 'delete',
    url: '/universal/qualityInformationFeedback/delete/' + id
  })
}

export function updateQualityInformationFeedback (data) {
  return axios({
    method: 'put',
    url: '/universal/qualityInformationFeedback/update',
    data: qs.stringify(data)
  })
}

export function selectQualityInformationFeedbackInfo (id) {
  return axios({
    method: 'get',
    url: '/universal/qualityInformationFeedback/info/' + id
  })
}

export function selectQualityInformationFeedbackList (query) {
  return axios({
    method: 'get',
    url: '/universal/qualityInformationFeedback/list',
    params: query
  })
}

export function confirmQualityInformationFeedback (data) {
  return axios({
    method: 'put',
    url: '/universal/qualityInformationFeedback/confirm',
    data: qs.stringify(data)
  })
}

export function analysisQualityInformationFeedback (data) {
  return axios({
    method: 'put',
    url: '/universal/qualityInformationFeedback/analysis',
    data: qs.stringify(data)
  })
}

export function handleQualityInformationFeedback (data) {
  return axios({
    method: 'put',
    url: '/universal/qualityInformationFeedback/handle',
    data: qs.stringify(data)
  })
}
