<template>
  <div id="qualityInformationFeedback">
    <el-dialog
      :title="qualityInformationFeedbackFormTitle"
      width="1200px"
      :visible.sync="qualityInformationFeedbackDialogVisible"
      :close-on-click-modal="false"
      @close="qualityInformationFeedbackDialogClose"
    >
      <el-form
        ref="qualityInformationFeedbackFormRef"
        :model="qualityInformationFeedbackForm"
        :rules="qualityInformationFeedbackFormRules"
        label-position="right"
        label-width="150px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="填写部门" prop="editDept">
              <el-select
                v-model="qualityInformationFeedbackForm.editDept"
                placeholder="请选择填写部门"
                clearable
                :disabled="qualityInformationFeedbackFormTitle !== '新增质量信息反馈表'
                  && qualityInformationFeedbackFormTitle !== '修改质量信息反馈表'
                  && qualityInformationFeedbackFormTitle !== '质量信息反馈表详情'"
                @change="deptChange"
              >
                <el-option
                  v-for="item in ['生产设备部', '质量部', '采购部', '仓储部', '销售部']"
                  :key="item"
                  :label="item"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="编号" prop="formNo">
              <el-input
                v-model="qualityInformationFeedbackForm.formNo"
                placeholder="请输入编号"
                clearable
                :disabled="qualityInformationFeedbackFormTitle !== '新增质量信息反馈表'
                  && qualityInformationFeedbackFormTitle !== '修改质量信息反馈表'
                  && qualityInformationFeedbackFormTitle !== '质量信息反馈表详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="产品名称" prop="productName">
              <el-input
                v-model="qualityInformationFeedbackForm.productName"
                placeholder="请输入产品名称"
                clearable
                :disabled="qualityInformationFeedbackFormTitle !== '新增质量信息反馈表'
                  && qualityInformationFeedbackFormTitle !== '修改质量信息反馈表'
                  && qualityInformationFeedbackFormTitle !== '质量信息反馈表详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="规格型号" prop="spec">
              <el-input
                v-model="qualityInformationFeedbackForm.spec"
                placeholder="请输入规格型号"
                clearable
                :disabled="qualityInformationFeedbackFormTitle !== '新增质量信息反馈表'
                  && qualityInformationFeedbackFormTitle !== '修改质量信息反馈表'
                  && qualityInformationFeedbackFormTitle !== '质量信息反馈表详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="批号" prop="batchNo">
              <el-input
                v-model="qualityInformationFeedbackForm.batchNo"
                placeholder="请输入批号"
                clearable
                :disabled="qualityInformationFeedbackFormTitle !== '新增质量信息反馈表'
                  && qualityInformationFeedbackFormTitle !== '修改质量信息反馈表'
                  && qualityInformationFeedbackFormTitle !== '质量信息反馈表详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="数量" prop="quantity">
              <el-input
                v-model="qualityInformationFeedbackForm.quantity"
                placeholder="请输入数量"
                clearable
                :disabled="qualityInformationFeedbackFormTitle !== '新增质量信息反馈表'
                  && qualityInformationFeedbackFormTitle !== '修改质量信息反馈表'
                  && qualityInformationFeedbackFormTitle !== '质量信息反馈表详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="反馈部门/人员" prop="feedbackDept">
              <el-input
                v-model="qualityInformationFeedbackForm.feedbackDept"
                placeholder="请输入反馈部门/人员"
                clearable
                :disabled="qualityInformationFeedbackFormTitle !== '新增质量信息反馈表'
                  && qualityInformationFeedbackFormTitle !== '修改质量信息反馈表'
                  && qualityInformationFeedbackFormTitle !== '质量信息反馈表详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="反馈时间" prop="feedbackTime">
              <el-date-picker
                v-model="qualityInformationFeedbackForm.feedbackTime"
                placeholder="请选择反馈时间"
                value-format="yyyy-MM-dd"
                :disabled="qualityInformationFeedbackFormTitle !== '新增质量信息反馈表'
                  && qualityInformationFeedbackFormTitle !== '修改质量信息反馈表'
                  && qualityInformationFeedbackFormTitle !== '质量信息反馈表详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="质量问题描述" prop="problemDescription">
              <el-input
                v-model="qualityInformationFeedbackForm.problemDescription"
                placeholder="请输入质量问题描述"
                type="textarea"
                :rows="3"
                :disabled="qualityInformationFeedbackFormTitle !== '新增质量信息反馈表'
                  && qualityInformationFeedbackFormTitle !== '修改质量信息反馈表'
                  && qualityInformationFeedbackFormTitle !== '质量信息反馈表详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="确定责任部门/人员" prop="determineDept">
              <el-input
                v-model="qualityInformationFeedbackForm.determineDept"
                placeholder="请输入确定责任部门/人员"
                type="textarea"
                :rows="3"
                :disabled="qualityInformationFeedbackFormTitle !== '质量信息反馈表确定责任部门/人员'
                  && qualityInformationFeedbackFormTitle !== '质量信息反馈表详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="质量问题分析和结果" prop="analysisResults">
              <el-input
                v-model="qualityInformationFeedbackForm.analysisResults"
                placeholder="请输入质量问题分析和结果"
                type="textarea"
                :rows="3"
                :disabled="qualityInformationFeedbackFormTitle !== '质量信息反馈表质量问题分析和结果'
                  && qualityInformationFeedbackFormTitle !== '质量信息反馈表详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="解决方案/计划" prop="solutionPlan">
              <el-input
                v-model="qualityInformationFeedbackForm.solutionPlan"
                placeholder="请输入解决方案/计划"
                type="textarea"
                :rows="3"
                :disabled="qualityInformationFeedbackFormTitle !== '质量信息反馈表质量问题分析和结果'
                  && qualityInformationFeedbackFormTitle !== '质量信息反馈表详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="处理意见" prop="handlingOpinions">
              <el-radio-group
                v-model="qualityInformationFeedbackForm.handlingOpinions"
                :disabled="qualityInformationFeedbackFormTitle !== '质量信息反馈表处理意见'
                  && qualityInformationFeedbackFormTitle !== '质量信息反馈表详情'"
              >
                <el-radio :label="1">
                  需要采取纠正和预防措施
                </el-radio>
                <el-radio :label="2">
                  不需要采取纠正和预防措施
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="编号" prop="serialNumber">
              <el-input
                v-model="qualityInformationFeedbackForm.serialNumber"
                placeholder="请输入编号"
                clearable
                :disabled="qualityInformationFeedbackFormTitle !== '质量信息反馈表处理意见'
                  && qualityInformationFeedbackFormTitle !== '质量信息反馈表详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="理由" prop="reason">
              <el-input
                v-model="qualityInformationFeedbackForm.reason"
                placeholder="请输入理由"
                clearable
                :disabled="qualityInformationFeedbackFormTitle !== '质量信息反馈表处理意见'
                  && qualityInformationFeedbackFormTitle !== '质量信息反馈表详情'"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="qualityInformationFeedbackDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="qualityInformationFeedbackFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="批号">
        <el-input v-model="searchForm.batchNo" placeholder="请输入批号" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          v-if="checkPermission(['QUALITY_INFORMATION_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="qualityInformationFeedbackPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :row-class-name="tableRowClassName"
      :height="getTableHeight()"
    >
      <el-table-column prop="formNo" label="编号" />
      <el-table-column prop="editDept" label="填写部门" />
      <el-table-column prop="productName" label="产品名称" />
      <el-table-column prop="spec" label="规格型号" />
      <el-table-column prop="batchNo" label="批号" />
      <el-table-column prop="quantity" label="数量" />
      <el-table-column prop="feedbackDept" label="反馈部门/人员" />
      <el-table-column label="反馈时间">
        <template slot-scope="scope">
          <span v-if="scope.row.feedbackTime">{{ scope.row.feedbackTime.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="problemDescription" label="质量问题描述" />
      <el-table-column prop="describePerson" label="描述人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.describeDate">{{ scope.row.describeDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="determineDept" label="确定责任部门/人员" />
      <el-table-column prop="manager1" label="管理者代表" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.managerDate1">{{ scope.row.managerDate1.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="analysisResults" label="质量问题分析和结果" />
      <el-table-column prop="solutionPlan" label="解决方案/计划" />
      <el-table-column prop="responsibleDept" label="责任部门或人员" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.responsibleDeptDate">{{ scope.row.responsibleDeptDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="处理意见">
        <template slot-scope="scope">
          <span v-if="scope.row.handlingOpinions === 1">需要采取纠正和预防措施</span>
          <span v-if="scope.row.handlingOpinions === 2">不需要采取纠正和预防措施</span>
        </template>
      </el-table-column>
      <el-table-column prop="serialNumber" label="编号" />
      <el-table-column prop="reason" label="理由" />
      <el-table-column prop="manager2" label="管理者代表" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.managerDate2">{{ scope.row.managerDate2.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['QUALITY_INFORMATION_DELETE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="checkPermission(['QUALITY_INFORMATION_UPDATE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            v-if="checkPermission(['QUALITY_INFORMATION_CONFIRM']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleConfirm(scope.$index, scope.row)"
          >
            确定责任部门/人员
          </el-button>
          <el-button
            v-if="checkPermission(['QUALITY_INFORMATION_ANALYSIS']) && scope.row.status === 2"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleAnalysis(scope.$index, scope.row)"
          >
            质量问题分析和结果
          </el-button>
          <el-button
            v-if="checkPermission(['QUALITY_INFORMATION_HANDLE']) && scope.row.status === 3"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleHandle(scope.$index, scope.row)"
          >
            处理意见
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="qualityInformationFeedbackPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addQualityInformationFeedback,
  deleteQualityInformationFeedback,
  updateQualityInformationFeedback,
  selectQualityInformationFeedbackInfo,
  selectQualityInformationFeedbackList,
  confirmQualityInformationFeedback,
  analysisQualityInformationFeedback,
  handleQualityInformationFeedback
} from '@/api/universal/qualityInformationFeedback'
import moment from 'moment'

export default {
  data () {
    return {
      qualityInformationFeedbackDialogVisible: false,
      qualityInformationFeedbackFormTitle: '',
      qualityInformationFeedbackForm: {
        id: '',
        formNo: '',
        editDept: '',
        productName: '',
        spec: '',
        batchNo: '',
        quantity: '',
        feedbackDept: '',
        feedbackTime: '',
        problemDescription: '',
        determineDept: '',
        analysisResults: '',
        solutionPlan: '',
        handlingOpinions: '',
        serialNumber: '',
        reason: '',
        taskId: ''
      },
      qualityInformationFeedbackFormRules: {
        batchNo: [{ required: true, message: '批号不能为空', trigger: ['blur', 'change']}]
      },
      qualityInformationFeedbackPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        batchNo: ''
      }
    }
  },
  created () {
    selectQualityInformationFeedbackList(this.searchForm).then(res => {
      this.qualityInformationFeedbackPage = res
    })
  },
  methods: {
    qualityInformationFeedbackDialogClose () {
      this.$refs.qualityInformationFeedbackFormRef.resetFields()
    },
    qualityInformationFeedbackFormSubmit () {
      if (this.qualityInformationFeedbackFormTitle === '质量信息反馈表详情') {
        this.qualityInformationFeedbackDialogVisible = false
        return
      }
      this.$refs.qualityInformationFeedbackFormRef.validate(async valid => {
        if (valid) {
          if (this.qualityInformationFeedbackFormTitle === '新增质量信息反馈表') {
            await addQualityInformationFeedback(this.qualityInformationFeedbackForm)
          } else if (this.qualityInformationFeedbackFormTitle === '修改质量信息反馈表') {
            await updateQualityInformationFeedback(this.qualityInformationFeedbackForm)
          } else if (this.qualityInformationFeedbackFormTitle === '质量信息反馈表确定责任部门/人员') {
            await confirmQualityInformationFeedback(this.qualityInformationFeedbackForm)
          } else if (this.qualityInformationFeedbackFormTitle === '质量信息反馈表质量问题分析和结果') {
            await analysisQualityInformationFeedback(this.qualityInformationFeedbackForm)
          } else if (this.qualityInformationFeedbackFormTitle === '质量信息反馈表处理意见') {
            await handleQualityInformationFeedback(this.qualityInformationFeedbackForm)
          }
          this.qualityInformationFeedbackPage = await selectQualityInformationFeedbackList(this.searchForm)
          this.qualityInformationFeedbackDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.qualityInformationFeedbackFormTitle = '新增质量信息反馈表'
      this.qualityInformationFeedbackDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteQualityInformationFeedback(row.id)
        if (this.qualityInformationFeedbackPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.qualityInformationFeedbackPage = await selectQualityInformationFeedbackList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.qualityInformationFeedbackFormTitle = '修改质量信息反馈表'
      this.qualityInformationFeedbackDialogVisible = true
      this.selectQualityInformationFeedbackInfoById(row.id)
    },
    handleConfirm (index, row) {
      this.qualityInformationFeedbackFormTitle = '质量信息反馈表确定责任部门/人员'
      this.qualityInformationFeedbackDialogVisible = true
      this.selectQualityInformationFeedbackInfoById(row.id)
    },
    handleAnalysis (index, row) {
      this.qualityInformationFeedbackFormTitle = '质量信息反馈表质量问题分析和结果'
      this.qualityInformationFeedbackDialogVisible = true
      this.selectQualityInformationFeedbackInfoById(row.id)
    },
    handleHandle (index, row) {
      this.qualityInformationFeedbackFormTitle = '质量信息反馈表处理意见'
      this.qualityInformationFeedbackDialogVisible = true
      this.selectQualityInformationFeedbackInfoById(row.id)
    },
    handleInfo (index, row) {
      this.qualityInformationFeedbackFormTitle = '质量信息反馈表详情'
      this.qualityInformationFeedbackDialogVisible = true
      this.selectQualityInformationFeedbackInfoById(row.id)
    },
    selectQualityInformationFeedbackInfoById (id) {
      selectQualityInformationFeedbackInfo(id).then(res => {
        this.qualityInformationFeedbackForm.id = res.id
        this.qualityInformationFeedbackForm.formNo = res.formNo
        this.qualityInformationFeedbackForm.editDept = res.editDept
        this.qualityInformationFeedbackForm.productName = res.productName
        this.qualityInformationFeedbackForm.spec = res.spec
        this.qualityInformationFeedbackForm.batchNo = res.batchNo
        this.qualityInformationFeedbackForm.quantity = res.quantity
        this.qualityInformationFeedbackForm.feedbackDept = res.feedbackDept
        this.qualityInformationFeedbackForm.feedbackTime = res.feedbackTime
        this.qualityInformationFeedbackForm.problemDescription = res.problemDescription
        this.qualityInformationFeedbackForm.determineDept = res.determineDept
        this.qualityInformationFeedbackForm.analysisResults = res.analysisResults
        this.qualityInformationFeedbackForm.solutionPlan = res.solutionPlan
        this.qualityInformationFeedbackForm.handlingOpinions = res.handlingOpinions
        this.qualityInformationFeedbackForm.serialNumber = res.serialNumber
        this.qualityInformationFeedbackForm.reason = res.reason
        this.qualityInformationFeedbackForm.taskId = res.taskId
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectQualityInformationFeedbackList(this.searchForm).then(res => {
        this.qualityInformationFeedbackPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectQualityInformationFeedbackList(this.searchForm).then(res => {
        this.qualityInformationFeedbackPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectQualityInformationFeedbackList(this.searchForm).then(res => {
        this.qualityInformationFeedbackPage = res
      })
    },
    tableRowClassName ({ row, rowIndex }) {
      if (this.checkPermission(['QUALITY_INFORMATION_CONFIRM']) && row.status === 1) {
        return 'review'
      } else if (this.checkPermission(['QUALITY_INFORMATION_ANALYSIS']) && row.status === 2) {
        return 'review'
      } else if (this.checkPermission(['QUALITY_INFORMATION_HANDLE']) && row.status === 3) {
        return 'review'
      }
      return ''
    },
    deptChange (value) {
      if (value === '生产设备部') {
        this.qualityInformationFeedbackForm.formNo = 'C' + moment().format('YYYYMMDD') + '-SCSB-'
      } else if (value === '质量部') {
        this.qualityInformationFeedbackForm.formNo = 'C' + moment().format('YYYYMMDD') + '-ZL-'
      } else if (value === '采购部') {
        this.qualityInformationFeedbackForm.formNo = 'C' + moment().format('YYYYMMDD') + '-CG-'
      } else if (value === '仓储部') {
        this.qualityInformationFeedbackForm.formNo = 'C' + moment().format('YYYYMMDD') + '-CC-'
      } else if (value === '销售部') {
        this.qualityInformationFeedbackForm.formNo = 'C' + moment().format('YYYYMMDD') + '-XS-'
      } else {
        this.qualityInformationFeedbackForm.formNo = ''
      }
    }
  }
}
</script>

<style>
</style>
